export const ACTIONS = {
    
    CREATE_NEW_CD:"CREATE_NEW_CD_POST",
    CREATE_NEW_CD_SUCCESS:"CREATE_NEW_CD_SUCCESS",
    CREATE_NEW_CD_FAIL:"CREATE_NEW_CD_FAIL",
    EDIT_CD:"EDIT_CD",
    EDIT_CD_SUCCESS:'EDIT_CD_SUCCESS',
    EDIT_CD_FAIL:'EDIT_CD_FAIL',
    DELETE_CD:"DELETE_CD",
    DELETE_CD_SUCCESS:'DELETE_CD_SUCCESS',
    DELETE_CD_FAIL:'DELETE_CD_FAIL',
    UPDATE_CD:"UPDATE_CD",
    UPDATE_CD_SUCCESS:"UPDATE_CD_SUCCESS",
    UPDATE_CD_FAIL:"UPDATE_CD_FAIL",
    UPDATE_CD_IMAGE:"UPDATE_CD_IMAGE",
    UPDATE_CD_IMAGE_SUCCESS:"UPDATE_CD_IMAGE_SUCCESS",
    UPDATE_CD_IMAGE_FAIL:"UPDATE_CD_IMAGE_FAIL",
    UPLOAD_CD_IMAGE:"UPLOAD_CD_IMAGE",
    UPLOAD_CD_IMAGE_SUCCESS:"UPLOAD_CD_IMAGE_SUCCESS",
    UPLOAD_CD_IMAGE_FAIL:"UPLOAD_CD_IMAGE_FAIL",

    CREATE_PUBLICATION_MUSIC:"CREATE_PUBLICATION_MUSIC",
    CREATE_PUBLICATION_MUSIC_SUCCESS:"CREATE_PUBLICATION_MUSIC_SUCCESS",
    CREATE_PUBLICATION_MUSIC_FAIL:"CREATE_PUBLICATION_MUSIC_FAIL",

    DELETE_PUBLICATION_MUSIC:"DELETE_PUBLICATION_MUSIC",
    DELETE_PUBLICATION_MUSIC_SUCCESS:"DELETE_PUBLICATION_MUSIC_SUCCESS",
    DELETE_PUBLICATION_MUSIC_FAIL:"DELETE_PUBLICATION_MUSIC_FAIL",

    LOAD_PUBLICATION_MUSIC:"LOAD_PUBLICATION_MUSIC",
    LOAD_PUBLICATION_MUSIC_SUCCESS:"LOAD_PUBLICATION_MUSIC_SUCCESS",
    LOAD_PUBLICATION_MUSIC_FAIL:"LOAD_PUBLICATION_MUSIC_FAIL",

    CREATE_CATALOGUE:"CREATE_CATALOGUE",
    CREATE_CATALOGUE_SUCCESS:"CREATE_CATALOGUE_SUCCESS",
    CREATE_CATALOGUE_FAIL:"CREATE_CATALOGUE_FAIL",

    CREATE_ORDER_LOCATION:"CREATE_ORDER_LOCATION",
    CREATE_ORDER_LOCATION_SUCCESS:"CREATE_ORDER_LOCATION_SUCCESS",

    CREATE_NEW_AGENDA:"CREATE_NEW_AGENDA",
    CREATE_NEW_AGENDA_SUCCESS:"CREATE_NEW_AGENDA_SUCCESS",
    CREATE_NEW_AGENDA_FAIL:"CREATE_NEW_AGENDA_FAIL",

    CREATE_NEW_POST:"CREATE_NEW_POST",
    CREATE_NEW_POST_SUCCESS:"CREATE_NEW_POST_SUCCESS",
    CREATE_NEW_POST_FAIL:"CREATE_NEW_POST_FAIL",

    CREATE_NEW_POST_IMAGE:"CREATE_NEW_POST_IMAGE",
    CREATE_NEW_POST_IMAGE_SUCCESS:"CREATE_NEW_POST_IMAGE_SUCCESS",
    CREATE_NEW_POST_IMAGE_FAIL:"CREATE_NEW_POST_IMAGE_FAIL",

    CREATE_NEW_PUBLICATION:"CREATE_NEW_PUBLICATION",
    CREATE_NEW_PUBLICATION_SUCCESS:"CREATE_NEW_PUBLICATION_SUCCESS",
    CREATE_NEW_PUBLICATION_FAIL:"CREATE_NEW_PUBLICATION_FAIL",

    DELETE_CATALOGUE:"DELETE_CATALOGUE",
    DELETE_CATALOGUE_SUCCESS:"DELETE_CATALOGUE_SUCCESS",
    DELETE_CATALOGUE_FAIL:"DELETE_CATALOGUE_FAIL",

    DELETE_PRESENTATION:"DELETE_PRESENTATION",
    DELETE_PRESENTATION_SUCCESS:"DELETE_PRESENTATION_SUCCESS",
    DELETE_PRESENTATION_FAIL:"DELETE_PRESENTATION_FAIL",

    DELETE_PRESENTATION_IMAGE:"DELETE_PRESENTATION_IMAGE",
    DELETE_PRESENTATION_IMAGE_SUCCESS:"DELETE_PRESENTATION_IMAGE_SUCCESS",
    DELETE_PRESENTATION_IMAGE_FAIL:"DELETE_PRESENTATION_IMAGE_FAIL",

    INSERT_POST_IMAGE:"INSERT_POST_IMAGE",
    INSERT_POST_IMAGE_SUCCESS:"INSERT_POST_IMAGE_SUCCESS",
    INSERT_POST_IMAGE_FAIL:"INSERT_POST_IMAGE_FAIL",

    LOAD_AGENDA:"LOAD_AGENDA",
    LOAD_AGENDA_SUCCESS:"LOAD_AGENDA_SUCCESS",
    LOAD_AGENDA_FAIL:"LOAD_AGENDA_FAIL",

    LOAD_CATALOGUE:"LOAD_CATALOGUE",
    LOAD_CATALOGUE_SUCCESS:"LOAD_CATALOGUE_SUCCESS",
    LOAD_CATALOGUE_FAIL:"LOAD_CATALOGUE_FAIL",

    LOGIN_USER:"LOGIN_USER",

    LOAD_POST:"LOAD_POST",
    LOAD_POST_SUCCESS:"LOAD_POST_SUCCESS",
    LOAD_POST_FAIL:"LOAD_POST_FAIL",

    LOAD_POSTS:"LOAD_POSTS",
    LOAD_POSTS_SUCCESS:"LOAD_POSTS_SUCCESS",
    LOAD_POSTS_FAIL:"LOAD_POSTS_FAIL",

    LOAD_PRESENTATION:"LOAD_PRESENTATION",
    LOAD_PRESENTATION_SUCCESS:"LOAD_PRESENTATION_SUCCESS",
    LOAD_PRESENTATION_FAIL:"LOAD_PRESENTATION_FAIL",

    LOAD_PRESENTATION_IMAGES:"LOAD_PRESENTATION_IMAGES",
    LOAD_PRESENTATION_IMAGES_SUCCESS:"LOAD_PRESENTATION_IMAGES_SUCCESS",
    LOAD_PRESENTATION_IMAGES_FAIL:"LOAD_PRESENTATION_IMAGES_FAIL",

    LOAD_DASHBOARD_POSTS:"LOAD_DASHBOARD_POSTS",
    LOAD_DASHBOARD_POSTS_SUCCESS:"LOAD_DASHBOARD_POSTS_SUCCESS",
    LOAD_DASHBOARD_POSTS_FAIL:"LOAD_DASHBOARD_POSTS_FAIL",
    
    LOAD_ARTICLE:"LOAD_ARTICLE",
    LOAD_ARTICLE_SUCCESS:"LOAD_ARTICLE_SUCCESS",
    LOAD_ARTICLE_FAIL:"LOAD_ARTICLE_FAIL",

    EDIT_POST:"EDIT_POST",
    EDIT_POST_SUCCESS:'EDIT_POST_SUCCESS',
    EDIT_POST_FAIL:'EDIT_POST_FAIL',

    DELETE_AGENDA:"DELETE_AGENDA",
    DELETE_AGENDA_SUCCESS:"DELETE_AGENDA_SUCCESS",
    
    DELETE_CONTACT:"DELETE_CONTACT",
    DELETE_CONTACT_SUCCESS:"DELETE_CONTACT_SUCCESS",
    DELETE_CONTACT_FAIL:"DELETE_CONTACT_FAIL",

    DELETE_POST:"DELETE_POST",
    DELETE_POST_SUCCESS:'DELETE_POST_SUCCESS',
    DELETE_POST_FAIL:'DELETE_POST_FAIL',

    DELETE_POST_IMAGE:"DELETE_POST_IMAGE",
    DELETE_POST_IMAGE_SUCCESS:'DELETE_POST_IMAGE_SUCCESS',
    DELETE_POST_IMAGE_FAIL:'DELETE_POST_IMAGE_FAIL',

    DELETE_SUBMISSION:"DELETE_SUBMISSION",
    DELETE_SUBMISSION_SUCCESS:'DELETE_SUBMISSION_SUCCESS',
    DELETE_SUBMISSION_FAIL:'DELETE_SUBMISSION_FAIL',


    LOAD_EVENT:"LOAD_EVENT",
    LOAD_EVENT_SUCCESS:"LOAD_EVENT_SUCCESS",
    LOAD_EVENT_FAIL:"LOAD_EVENT_FAIL",

    LOAD_EVENTS:"LOAD_EVENTS",
    LOAD_EVENTS_SUCCESS:"LOAD_EVENTS_SUCCESS",
    LOAD_EVENTS_FAIL:"LOAD_EVENTS_FAIL",
   
    LOAD_POST_IMAGE:"LOAD_POST_IMAGE",
    LOAD_POST_IMAGE_SUCCESS:'LOAD_POST_IMAGE_SUCCESS',
    LOAD_POST_IMAGES:"LOAD_POST_IMAGES",
    LOAD_POST_IMAGES_SUCCESS:"LOAD_POST_IMAGES_SUCCESS",
    LOAD_POST_IMAGES_FAIL:"LOAD_POST_IMAGES_FAIL",

    LOAD_PRESENTATIONS:"LOAD_PRESENTATIONS",
    LOAD_PRESENTATIONS_SUCCESS:"LOAD_PRESENTATIONS_SUCCESS",
    LOAD_PRESENTATIONS_FAIL:"LOAD_PRESENTATIONS_FAIL",

    LOAD_ALL_PRESENTATIONS:"LOAD_ALL_PRESENTATIONS",
    LOAD_ALL_PRESENTATIONS_SUCCESS:"LOAD_ALL_PRESENTATIONS_SUCCESS",
    LOAD_ALL_PRESENTATIONS_FAIL:"LOAD_ALL_PRESENTATIONS_FAIL",

    LOAD_PRESENTATION_MUSIC:"LOAD_PRESENTATION_MUSIC",
    LOAD_PRESENTATION_MUSIC_SUCCESS:"LOAD_PRESENTATION_MUSIC_SUCCESS",

    CREATE_CD_INFO:"CREATE_CD_INFO",
    CREATE_CD_INFO_SUCCESS:"CREATE_CD_INFO_SUCCESS",
    CREATE_CD_INFO_FAIL:"CREATE_CD_INFO_FAIL",

    DELETE_CD_INFO:"DELETE_CD_INFO",
    DELETE_CD_INFO_SUCCESS:"DELETE_CD_INFO_SUCCESS",
    DELETE_CD_INFO_FAIL:"DELETE_CD_INFO_FAIL",

    UPDATE_CD_INFO:"UPDATE_CD_INFO",
    UPDATE_CD_INFO_SUCCESS:"UPDATE_CD_INFO_SUCCESS",
    UPDATE_CD_INFO_FAIL:"UPDATE_CD_INFO_FAIL",

    LOAD_CD:"LOAD_CD",
    LOAD_CD_SUCCESS:"LOAD_CD_SUCCESS",
    LOAD_CD_FAIL:"LOAD_CD_FAIL",

    LOAD_CDS:"LOAD_CDS",
    LOAD_CDS_SUCCESS:"LOAD_CDS_SUCCESS",
    LOAD_CDS_FAIL:"LOAD_CDS_FAIL",

    LOAD_CD_INFO:"LOAD_CD_INFO",
    LOAD_CD_INFO_SUCCESS:"LOAD_CD_INFO_SUCCESS",
    LOAD_CD_INFO_FAIL:"LOAD_CD_INFO_FAIL",

    LOAD_CD_AGENDA:"LOAD_CD_AGENDA",
    LOAD_CD_AGENDA_SUCCESS:"LOAD_CD_AGENDA_SUCCESS",
    LOAD_CD_AGENDA_FAIL:"LOAD_CD_AGENDA_FAIL",

    LOAD_DASHBOARD_CD_AGENDA:"LOAD_DASHBOARD_CD_AGENDA",
    LOAD_DASHBOARD_CD_AGENDA_SUCCESS:"LOAD_DASHBOARD_CD_AGENDA_SUCCESS",


    LOAD_CONTACT:"LOAD_CONTACT",
    LOAD_CONTACT_SUCCESS:"LOAD_CONTACT_SUCCESS",
    LOAD_CONTACT_FAIL:"LOAD_CONTACT_FAIL",

    LOAD_CONTACTS:"LOAD_CONTACTS",
    LOAD_CONTACTS_SUCCESS:"LOAD_CONTACTS_SUCCESS",
    LOAD_CONTACTS_FAIL:"LOAD_CONTACTS_FAIL",

    LOAD_AGENDAS:"LOAD_AGENDAS",
    LOAD_AGENDAS_SUCCESS:"LOAD_AGENDAS_SUCCESS",
    LOAD_AGENDAS_FAIL:"LOAD_AGENDAS_FAIL",

    LOAD_AGENDAS_FILTERED:"LOAD_AGENDAS_FILTERED",
    LOAD_AGENDAS_FILTERED_SUCCESS:"LOAD_AGENDAS_FILTERED_SUCCESS",
    LOAD_AGENDAS_FILTERED_FAIL:"LOAD_AGENDAS_FILTERED_FAIL",

    LOAD_ALL_AGENDAS:"LOAD_ALL_AGENDAS",
    LOAD_ALL_AGENDAS_SUCCESS:"LOAD_ALL_AGENDAS_SUCCESS",
    LOAD_ALL_AGENDAS_FAIL:"LOAD_ALL_AGENDAS_FAIL",

    LOAD_HEADER:"LOAD_HEADER",
    LOAD_HEADER_SUCCESS:"LOAD_HEADER_SUCCESS",
    LOAD_HEADER_FAIL:"LOAD_HEADER_FAIL",

    LOAD_MUSIC_CATALOGUE:"LOAD_MUSIC_CATALOGUE",
    LOAD_MUSIC_CATALOGUE_SUCCESS:"LOAD_MUSIC_CATALOGUE_SUCCESS",
    LOAD_MUSIC_CATALOGUE_FAIL:"LOAD_MUSIC_CATALOGUE_FAIL",

    LOAD_MUSIC:"LOAD_MUSIC",
    LOAD_MUSIC_SUCCESS:"LOAD_MUSIC_SUCCESS",
    LOAD_MUSIC_FAIL:"LOAD_MUSIC_FAIL",

    LOAD_MUSIC_PROFILE:"LOAD_MUSIC_PROFILE",
    LOAD_MUSIC_PROFILE_SUCCESS:"LOAD_MUSIC_PROFILE_SUCCESS",
    LOAD_MUSIC_PROFILE_FAIL:"LOAD_MUSIC_PROFILE_FAIL",
    LOAD_MUSIC_PROFILE_BY_ID:"LOAD_MUSIC_PROFILE_BY_ID",

    LOAD_MUSIC_BY_CATEGORY:"LOAD_MUSIC_BY_CATEGORY",
    LOAD_MUSIC_BY_CATEGORY_SUCCESS:"LOAD_MUSIC_BY_CATEGORY_SUCCESS",

    LOAD_ORDER_LOCATIONS:"LOAD_ORDER_LOCATIONS",
    LOAD_ORDER_LOCATIONS_SUCCESS:"LOAD_ORDER_LOCATIONS_SUCCESS",
    LOAD_ORDER_LOCATIONS_FAIL:"LOAD_ORDER_LOCATIONS_FAIL",

    LOAD_ORDER_LOCATION:"LOAD_ORDER_LOCATION",
    LOAD_ORDER_LOCATION_SUCCESS:"LOAD_ORDER_LOCATION_SUCCESS",
    LOAD_ORDER_LOCATION_FAIL:"LOAD_ORDER_LOCATION_FAIL",

    LOAD_PAGE:'LOAD_PAGE',
    LOAD_PAGE_SUCCESS:'LOAD_PAGE_SUCCESS',
    LOAD_PAGES:'LOAD_PAGES',
    LOAD_PAGES_SUCCESS:'LOAD_PAGES_SUCCESS',


    LOAD_GALLERIES:"LOAD_GALLERIES",
    LOAD_GALLERIES_SUCCESS:"LOAD_GALLERIES_SUCCESS",
    LOAD_GALLERIES_FAIL:"LOAD_GALLERIES_FAIL",

    LOAD_GALLERY:"LOAD_GALLERY",
    LOAD_GALLERY_SUCCESS:"LOAD_GALLERY_SUCCESS",
    LOAD_GALLERY_FAIL:"LOAD_GALLERY_FAIL",

    DELETE_GALLERY:"DELETE_GALLERY",
    DELETE_GALLERY_SUCCESS:"DELETE_GALLERY_SUCCESS",
    DELETE_GALLERY_FAIL:"DELETE_GALLERY_FAIL",

    UPDATE_GALLERY:"UPDATE_GALLERY",
    UPDATE_GALLERY_SUCCESS:"UPDATE_GALLERY_SUCCESS",
    UPDATE_GALLERY_FAIL:"UPDATE_GALLERY_FAIL",

    INSERT_GALLERY_IMAGE:"INSERT_GALLERY_IMAGE",
    INSERT_GALLERY_IMAGE_SUCCESS:"INSERT_GALLERY_IMAGE_SUCCESS",
    INSERT_GALLERY_IMAGE_FAIL:"INSERT_GALLERY_IMAGE_FAIL",

    DELETE_GALLERY_IMAGE:"DELETE_GALLERY_IMAGE",
    DELETE_GALLERY_IMAGE_SUCCESS:"DELETE_GALLERY_IMAGE_SUCCESS",
    DELETE_GALLERY_IMAGE_FAIL:"DELETE_GALLERY_IMAGE_FAIL",

    UPDATE_GALLERY_IMAGE:"UPDATE_GALLERY_IMAGE",
    UPDATE_GALLERY_IMAGE_SUCCESS:"UPDATE_GALLERY_IMAGE_SUCCESS",
    UPDATE_GALLERY_IMAGE_FAIL:"UPDATE_GALLERY_IMAGE_FAIL",


    UPDATE_PAGE:"UPDATE_PAGE",
    UPDATE_PAGE_SUCCESS:"UPDATE_PAGE_SUCCESS",


    UPDATE_ORDER_LOCATION:"UPDATE_ORDER_LOCATION",
    UPDATE_ORDER_LOCATION_SUCCESS:"UPDATE_ORDER_LOCATION_SUCCESS",
    UPDATE_ORDER_LOCATION_FAIL:"UPDATE_ORDER_LOCATION_FAIL",

    DELETE_ORDER_LOCATION:"DELETE_ORDER_LOCATION",
    DELETE_ORDER_LOCATION_SUCCESS:"DELETE_ORDER_LOCATION_SUCCESS",
    DELETE_ORDER_LOCATION_FAIL:"DELETE_ORDER_LOCATION_FAIL",

    LOAD_SUBMISSION:"LOAD_SUBMISSION",
    LOAD_SUBMISSION_SUCCESS:"LOAD_SUBMISSION_SUCCESS",
    LOAD_SUBMISSION_FAIL:"LOAD_SUBMISSION_FAIL",
    
    LOAD_SUBMISSIONS:"LOAD_SUBMISSIONS",
    LOAD_SUBMISSIONS_SUCCESS:"LOAD_SUBMISSIONS_SUCCESS",
    LOAD_SUBMISSIONS_FAIL:"LOAD_SUBMISSIONS_FAIL",

    LOGIN_USER:'LOGIN_USER',
    LOGIN_USER_SUCCESS:"LOGIN_USER_SUCCESS",
    LOGIN_USER_FAIL:"LOGIN_USER_FAIL",
    LOGOUT:"LOGOUT",
    LOGOUT_SUCCESS:"LOGOUT_SUCCESS",
    LOGOUT_ERROR:"LOGOUT_ERRO",
    LOGIN_CHECK:"LOGIN_CHECK",

  
    RESET_IMAGE:"RESET_IMAGE",

    SUBMIT_CONCERT_INFORMATION:"SUBMIT_CONCERT_INFORMATION",
    SUBMIT_CONCERT_INFORMATION_SUCCESS:"SUBMIT_CONCERT_INFORMATION_SUCCESS",
    SUBMIT_CONCERT_INFORMATION_FAIL:"SUBMIT_CONCERT_INFORMATION_FAIL",

    SUBMIT_CONTACT_INFO:"SUBMIT_CONTACT_INFO",
    SUBMIT_CONTACT_INFO_SUCCESS:"SUBMIT_CONTACT_INFO_SUCCESS",
    SUBMIT_CONTACT_INFO_FAIL:"SUBMIT_CONTACT_INFO_FAIL",

    

    UPDATE_AGENDA:"UPDATE_AGENDA",
    UPDATE_AGENDA_SUCCESS:"UPDATE_AGENDA_SUCCESS",
    UPDATE_AGENDA_FAIL:"UPDATE_AGENDA_FAIL",

    UPDATE_CATALOGUE:"UPDATE_CATALOGUE",
    UPDATE_CATALOGUE_SUCCESS:"UPDATE_CATALOGUE_SUCCESS",
    UPDATE_CATALOGUE_FAIL:"UPDATE_CATALOGUE_FAIL",

    UPLOAD_IMAGE:"UPLOAD_IMAGE",
    UPLOAD_IMAGE_SUCCESS:"UPLOAD_IMAGE_SUCCESS",
    UPLOAD_IMAGE_FAIL:"UPLOAD_IMAGE_FAIL",

    UPDATE_EVENT:"UPDATE_EVENT",
    UPDATE_EVENT_SUCCESS:'UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL:'UPDATE_EVENT_FAIL',

    UPDATE_POST:"UPDATE_POST",
    UPDATE_POST_SUCCESS:"UPDATE_POST_SUCCESS",
    UPDATE_POST_FAIL:"UPDATE_POST_FAIL",

    UPDATE_POST_IMAGE:"UPDATE_POST_IMAGE",
    UPDATE_POST_IMAGE_SUCCESS:"UPDATE_POST_IMAGE_SUCCESS",
    UPDATE_POST_IMAGE_FAIL:"UPDATE_POST_IMAGE_FAIL",

    UPLOAD_POST_IMAGE:"UPLOAD_POST_IMAGE",
    UPLOAD_POST_IMAGE_SUCCESS:"UPLOAD_POST_IMAGE_SUCCESS",
    UPLOAD_POST_IMAGE_FAIL:"UPLOAD_POST_IMAGE_FAIL",

   UPDATE_CAPTION:"UPDATE_CAPTION",
   UPDATE_CAPTION_SUCCESS:"UPDATE_CAPTION_SUCCESS",
   UPDATE_CAPTION_FAIL:"UPDATE_CAPTION_FAIL",

    UPDATE_PRESENTATION_IMAGE:"UPDATE_PRESENTATION_IMAGE",
    UPLOAD_PRESENTATION_IMAGE:"UPLOAD_PRESENTATION_IMAGE",
    UPLOAD_PRESENTATION_IMAGE_SUCCESS:"UPLOAD_PRESENTATION_IMAGE_SUCCESS",
    UPLOAD_PRESENTATION_IMAGE_FAIL:"UPLOAD_PRESENTATION_IMAGE_FAIL",
    
    UPDATE_PUBLICATION:"UPDATE_PUBLICATION",
    UPDATE_PUBLICATION_SUCCESS:"UPDATE_PUBLICATION_SUCCESS",
    UPDATE_PUBLICATION_FAIL:"UPDATE_PUBLICATION_FAIL",

}